import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
export default {
    components: { FullCalendar },
    data() {
        return {
            selectedDate: new Date,
            visibleRange: function(nowDate) {
                return {
                    start: nowDate,
                    end: nowDate.clone().add(3, 'months'),
                };
            },
            response: [],
            eventsOnSelectedDay: [],
            singleCalendarConfig: {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                selectable: true,
                contentHeight: 'auto',
                headerToolbar: {
                    left: 'prev',
                    center: 'title',
                    right: 'next',
                },
                longPressDelay: 0,
                events: [],
                select: this.dateSelected,
            },
            experienceType: 'events',
        }
    },
    computed: {
        activeUser() {
            return this.$store.getters['auth/getAuthenticatedUser']
        },
        isUserApproved() {
            if (this.$store.state.auth.user) {
                return this.activeUser.member_status === 'approved'
            }
            return false
        },
    },
    mounted() {
        this.getData();
        if(this.$route.query.type){
            this.experienceType = this.$route.query.type;
        }
    },
    methods: {
        getData() {
            let url = this.isUserApproved
                ? '/experiences'
                : '/experience-master-list'
            this.$http
                .get(url)
                .then((response) => {
                    if (response.status == 200) {
                        this.response = this.isUserApproved
                            ? response.data.response_data
                            : response.data.response_data.experiences

                        this.response = this.response.filter((item) => {
                            return item.type === this.experienceType
                        })
                        this.singleCalendarConfig.events = []
                        this.response.forEach((element) => {
                            this.singleCalendarConfig.events.push({
                                start: element.occurence_dates,
                                allDay: true,
                                display: 'background',
                            })
                        });
                        this.dateSelected(this.selectedDate);
                    }
                })
                .catch((e) => {
                    console.error(e)
                    this.$helper.showMessage(
                        'Oops! Something went wrong',
                        'danger'
                    )
                })
        },
        dateSelected(date) {
            this.selectedDate = date.start;
            this.eventsOnSelectedDay = this.response.filter((element) => {
                return element.occurence_dates === date.startStr
            });
        },
        goToEventDetail(eventObj) {
            this.$router.push({
                name: `experiences_details`,
                params: {
                    type: eventObj.type,
                    id: eventObj.id,
                    event_data: eventObj,
                },
            })
        },
    },
}
